import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import { findWithRegex, REGEX_MEDIA, REGEX_MEDIA_NAME, REGEX_MEDIA_URL } from "../regex";
var extractImagesFromPlainText = function extractImagesFromPlainText(_ref) {
  var plainText = _ref.plainText,
    mediaUrl = _ref.mediaUrl;
  var imageList = [];
  var plainTextWithoutImages = '';
  var lastIndex = 0;

  // Remove images from plain text and store them in an array
  findWithRegex(REGEX_MEDIA, plainText, function (startIndex, endIndex) {
    var _nameMatchResult$, _urlMatchResult$, _url;
    if (startIndex > lastIndex) {
      var precedingText = plainText.substring(lastIndex, startIndex);
      plainTextWithoutImages = "".concat(plainTextWithoutImages).concat(precedingText);
    }
    var matchedText = plainText.substring(startIndex, endIndex);
    var nameMatchResult = REGEX_MEDIA_NAME.exec(matchedText);
    var name = nameMatchResult !== null && nameMatchResult !== void 0 && nameMatchResult.length ? (_nameMatchResult$ = nameMatchResult[0]) === null || _nameMatchResult$ === void 0 ? void 0 : _nameMatchResult$.substring(5, nameMatchResult[0].length - 1) : '';
    var urlMatchResult = REGEX_MEDIA_URL.exec(matchedText);
    var url = urlMatchResult !== null && urlMatchResult !== void 0 && urlMatchResult.length ? (_urlMatchResult$ = urlMatchResult[0]) === null || _urlMatchResult$ === void 0 ? void 0 : _urlMatchResult$.substring(5, urlMatchResult[0].length - 1) : '';
    url = ((_url = url) === null || _url === void 0 ? void 0 : _url.substring(0, 6)) === 'images' ? "".concat(mediaUrl || '/').concat(url) : url;

    // Ignore possibly duplicated images
    if (!imageList.find(function (image) {
      return image.url === url;
    })) {
      imageList = _toConsumableArray(imageList);
      if (name && url) {
        imageList.push({
          name: name,
          url: url
        });
      }
    }
    lastIndex = endIndex;
  });
  if (lastIndex < plainText.length) {
    var remainingText = plainText.substring(lastIndex);
    plainTextWithoutImages = "".concat(plainTextWithoutImages).concat(remainingText);
  }
  return {
    imageList: imageList,
    plainTextWithoutImages: plainTextWithoutImages
  };
};
export default extractImagesFromPlainText;