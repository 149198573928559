import { CompositeDecorator } from 'draft-js';
import Bold from "../decorators/Bold";
import BoldItalic from "../decorators/BoldItalic";
import BoldItalicUnderline from "../decorators/BoldItalicUnderline";
import BoldUnderline from "../decorators/BoldUnderline";
import Email from "../decorators/Email";
import Italic from "../decorators/Italic";
import ItalicUnderline from "../decorators/ItalicUnderline";
import Link from "../decorators/Link";
import Underline from "../decorators/Underline";
import UserTag from "../decorators/UserTag";
import { findWithRegex, REGEX_BI, REGEX_BIU, REGEX_BOLD, REGEX_BU, REGEX_EMAIL, REGEX_ITALIC, REGEX_IU, REGEX_TAG, REGEX_UNDERLINE, REGEX_URL } from "../regex";
var createDecorator = function createDecorator(_ref) {
  var regex = _ref.regex,
    component = _ref.component,
    props = _ref.props;
  return {
    strategy: function strategy(contentBlock, callback) {
      findWithRegex(regex, contentBlock.getText(), callback);
    },
    component: component,
    props: props
  };
};
var createCompositeDecorator = function createCompositeDecorator(props) {
  return new CompositeDecorator([createDecorator({
    regex: REGEX_BOLD,
    component: Bold,
    props: props
  }), createDecorator({
    regex: REGEX_ITALIC,
    component: Italic,
    props: props
  }), createDecorator({
    regex: REGEX_UNDERLINE,
    component: Underline,
    props: props
  }), createDecorator({
    regex: REGEX_BI,
    component: BoldItalic,
    props: props
  }), createDecorator({
    regex: REGEX_BU,
    component: BoldUnderline,
    props: props
  }), createDecorator({
    regex: REGEX_BIU,
    component: BoldItalicUnderline,
    props: props
  }), createDecorator({
    regex: REGEX_IU,
    component: ItalicUnderline,
    props: props
  }), createDecorator({
    regex: REGEX_EMAIL,
    component: Email,
    props: props
  }), createDecorator({
    regex: REGEX_URL,
    component: Link,
    props: props
  }), createDecorator({
    regex: REGEX_TAG,
    component: UserTag,
    props: props
  })]);
};
export default createCompositeDecorator;